html {
    position: relative;
    min-height: 100%;
}

body {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

.bg-black {
    background-color: #212529 !important;
}
